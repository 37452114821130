.ico-mi {
    font-family: "Material Icons";

    &.icon-site-home:before { content: '\E80B'; }
    &.icon-dictionary:before { content: '\E02F'; }
    &.icon-finance:before { content: '\E263'; }
    &.icon-company:before { content: '\E8F9'; }
    &.icon-service:before { content: '\E87B'; }
    &.icon-home:before { content: '\E87A'; }
    &.icon-user:before { content: '\E8D3'; }
    &.icon-order:before { content: '\E873'; }
    &.icon-ved:before { content: '\E9BC'; }
    &.icon-logistic:before { content: '\E195'; }
    &.icon-administrator:before { content: '\EA67'; }
    &.icon-content:before { content: '\E165'; }
    &.icon-menu:before { content: '\E26C'; }
    &.icon-settings:before { content: '\E8B8'; }
    &.icon-search:before { content: '\E8B6'; }
    &.icon-clear:before { content: '\E5D5'; }
    &.icon-site:before { content: '\E912'; }
    &.icon-request:before { content: '\E91F'; }
    &.icon-cargo:before { content: '\E1BD'; }
    &.icon-project:before { content: '\E617'; }
}
.ico-i {
    font-family: "Icomoon";

    &.icon-add:before { content: '\E931'; }
    &.icon-dictionaries:before { content: '\E920'; }
    &.icon-home:before { content: '\E900'; }
    &.icon-documents:before { content: '\E9AE'; }
    &.icon-warehouse:before { content: '\E95D'; }
    &.icon-delete:before { content: '\E9AC'; }
    &.icon-edit:before { content: '\E905'; }
    &.icon-save:before { content: '\E962'; }
    &.icon-approved:before { content: '\EA10'; }
    &.icon-question:before { content: '\EA09'; }
    &.icon-sort:before { content: '\EA5B'; }
    &.icon-asc:before { content: '\EA4C'; }
    &.icon-desc:before { content: '\EA4D'; }
    &.icon-archive:before { content: '\E930'; }
    &.icon-view:before { content: '\E9CE'; }
}

.ico-fa {
    font-family: "FontAwesome";

    &.icon-dictionaries:before { content: '\E944'; }
    &.icon-home:before { content: '\F015'; }
    &.icon-documents:before { content: '\F016'; }
    &.icon-warehouse:before { content: '\F187'; }
    &.icon-delete:before { content: '\F014'; }
    &.icon-edit:before { content: '\F040'; }
    &.icon-save:before { content: '\F0C7'; }
    &.icon-approved:before { content: '\F00C'; }
    &.icon-question:before { content: '\F059'; }
    &.icon-sort:before { content: '\F0DC'; }
    &.icon-asc:before { content: '\F0DE'; }
    &.icon-desc:before { content: '\F0DD'; }
    &.icon-down:before { content: '\F107'; }
    &.icon-up:before { content: '\F106'; }

}


.ico-fa4 {
    font-family: "FontAwesome4";

    &.icon-other:before { content: '\f016'; }
    &.icon-word:before { content: '\f1c2'; }
    &.icon-excel:before { content: '\f1c3'; }
    &.icon-image:before { content: '\f1c5'; }
    &.icon-pdf:before { content: '\f1c1'; }
    &.icon-txt:before { content: '\f0f6'; }
    &.icon-archive:before { content: '\f1c6'; }
    &.icon-folder:before { content: '\f114'; }
}
