$mobile_width: 600px;
$tablet_width: 1024px;
$desktop_width: 1200px;

@mixin mobile {
    @media (max-width: $mobile_width) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: $tablet_width) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: $desktop_width) {
        @content;
    }
}
