@font-face {
    font-family: 'Icomoon';
    font-style: normal;
    font-weight: 400;
    //src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('IcoMoon-Free'),
    local('IcoMoon-Free'),
        //url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
        //url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/Icomoon/IcoMoon-Free.ttf) format('truetype');
}
@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    //src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Material Icons'),
    local('MaterialIcons-Regular'),
    //url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    //url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/MaterialIcons/MaterialIcons-Regular.ttf) format('truetype');
}
@font-face {
    font-family: 'Fontawesome';
    font-style: normal;
    font-weight: 400;
    //src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Fontawesome'),
    local('Fontawesome'),
    //url(../fonts/MaterialIcons-Regular.woff2) format('woff2'),
    //url(../fonts/MaterialIcons-Regular.woff) format('woff'),
    url(../fonts/Fontawesome/fontawesome-webfont.ttf) format('truetype');
}

@font-face {
    font-family: 'Fontawesome4';
    font-style: normal;
    font-weight: 400;
    //src: url(../fonts/MaterialIcons-Regular.eot); /* For IE6-8 */
    src: local('Fontawesome'),
    local('Fontawesome'),
    url(../fonts/Fontawesome4/fontawesome-webfont.woff2) format('woff2'),
    url(../fonts/Fontawesome4/fontawesome-webfont.woff) format('woff'),
    url(../fonts/Fontawesome4/fontawesome-webfont.ttf) format('truetype');
}
