// Fonts
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&display=swap');

// Variables
@import 'media';
@import 'variables';
@import 'fonts';
@import 'icons';

// Bootstrap
//@import '~bootstrap/scss/bootstrap';

* {
    padding: 0;
    margin: 0;
    font-size: 14px;
    font-family: $font-family;
    box-sizing: border-box;
}

html, body {
    background: $body-bg;
    min-width: 320px;
}

.p5 { padding: 5px; }
.p10 { padding: 10px; }

.m5 { margin: 5px; }
.m10 { margin: 10px; }

.w5 { width: 5%; }
.w10 { width: 10%; }
.w20 { width: 20%; }
.w25 { width: 25%; }
.w30 { width: 30%; }
.w33 { width: 33.3333%; }
.w40 { width: 40%; }
.w50 { width: 50%; }
.w60 { width: 60%; }
.w75 { width: 75%; }
.w100 { width: 100%; }

.text-l { text-align: left!important; }
.text-r { text-align: right!important; }
.text-c { text-align: center!important; }
.helper {
    color: #999;
    font-size: 0.9em;

    & a {
        color: #999; font-size: 0.9em; font-weight: bold;
    }
}


.tabs {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    border-bottom: 1px solid #ccc;
    padding: 5px 5px 0 5px;

    &__wrapper {}

    &__container {
        border: 1px solid #ccc;
        border-top: none;
        border-radius: 0 0 3px 3px;
        padding: 5px 10px;
    }

    .tab {
        padding: 7px 12px;
        white-space: nowrap;
        background: #f7f7f7;
        color: #3a4661;
        border: 1px solid #ccc;
        border-bottom: none;
        cursor: pointer;
        margin-right: 3px;
        border-radius: 5px 5px 0 0;

        &:last-child {
            //border-right: none;
        }

        &.active {
            background: #0b5ed7;
            color: #ffffff;
        }
        &.add {
            background: #b9b9b9;
            color: #ffffff;
            cursor: pointer;
        }

        &.labels {
            padding: 0;
            display: flex;
            & .label {
                padding: 7px 12px;
                display: block;
            }
            & .close {
                padding: 7px 12px;
                display: block;
            }
        }
    }
}


.link {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
    color: #333;
    margin: 2px;
}

.left {
    width: 90px;
    margin-right: -50px;
    position: fixed;
    top:0;
    left: 0;
    bottom: 0;
    background: #485779;
    color: #fff;
    overflow: hidden;
    z-index: 10;
    padding-right: 20px;

    & .title {
        font-size: 1.2em;
        font-weight: bold;
        text-align: center;
        padding: 10px;
    }

    & .menu {
        max-height: 100%;
        height: 100%;
        overflow-y: auto;
        //width: 90px;
        overflow-x: hidden;
        margin-right: -37px;

        & > ul > li {
            max-width: 90px;
        }

        & li {
            list-style: none;


            &.mobile-button {
                display: none;
            }

            & a, & span {
                display: block;
                text-decoration: none;
                color: #fff;
                padding: 10px;

                &:hover {
                    background: #4273FA;
                }

                &:active {
                    background: #507dff;
                }
            }

            & .submenu {
                display: none;
                width: 200px;
                position: fixed;
                top: 0;
                bottom: 0;
                left: 90px;
                background: #3a4661;
                z-index: 3;

                &__label {
                    padding: 10px;
                    padding-top: 30px;
                    text-transform: uppercase;
                    font-size: 0.7em;
                    font-weight: bold;
                    color: #bbbbbb;
                }
            }
        }

        & > ul > li {
            &:hover .submenu {
                display: block;
            }

            & > span, & > a {
                cursor: pointer;
                padding: 10px 0;
                text-align: center;

                & .icon {
                    padding: 0;
                    font-size: 2.5em;
                    color: #fff;
                }
                & .label {
                    padding: 0;
                    padding-top: 5px;
                    font-size: 0.8em;
                    color: #fff;
                }
            }
        }
    }
}
.right {
    margin-left: 90px;
    position: relative;
    & .top {
        background: #fff;
        border-bottom: 1px solid #D6DEE3;
        color: #73818f;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        z-index: 999;
        white-space: nowrap;

        & .title {
            font-weight: bold;
            text-align: center;
        }

        & .user {
            display: flex;
            flex-direction: row;

            & .currencies {
                display: flex;
                .item {
                    margin-right: 10px;
                }
                .label {
                    font-weight: 600;
                }
            }
            & .profile {

            }

            & .exit {
                text-decoration: underline;
                padding: 0 5px;
                cursor: pointer;
            }

            & .language {
                & ul {
                    display: flex;
                    flex-direction: row;
                    & li {
                        list-style: none;
                        text-transform: uppercase;
                        padding: 0 10px;
                        border-right: 1px solid #fff;
                        cursor: pointer;

                        &:last-child {
                            border-right: none;
                        }

                        &.active {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
        & .mobile-menu {
            display: none;
        }

    }

    & .content {
        padding: 10px;
        //max-width: $desktop_width;
    }
}


.image-cover {
    width: 100px;
    height: 100px;
    background-size: cover;
    background-position: center center;
}
.avatar {
    width: 150px;
    height: 150px;
}

.formBlock {
    padding: 10px;
    & .label {
        padding-bottom: 5px;
        font-size: 0.8em;
        text-transform: uppercase;
        font-weight: bold;
        color:#5b5b5b;

        &.required {
            color:#333333;
        }
    }
    &.smallBorder {
        padding: 5px;
    }
    &.notBorder {
        padding: 0;
        & .body {
            padding: 0;
        }
    }
}



.button {
    text-decoration: none;
    position: relative;
    border: 1px solid #ccc;
    outline: none;
    background: #fff;
    border-radius: 3px;
    text-align: center;
    padding: 5px 10px;
    cursor: pointer;
    color: #333;
    margin: 3px;
    display: inline-block;
    line-height: 1.3;
    &:hover { background: #ececec; }
    &:active { background: #e2e2e2; }
    & .label {
        margin-left: 5px;
    }
}
.btn-danger {
    color: #fff;
    background-color: #f87171;
    border-color: #f87171;
    &:hover {
        color: #fff;
        background-color: #bb2d3b;
        border-color: #b02a37;
    }
    &:active {
        color: #fff;
        background-color: #b02a37;
        border-color: #a52834;
    }
}
.btn-primary {
    color: #fff;
    background-color: #0ea5e9;
    border-color: #0ea5e9;
    &:hover {
        color: #fff;
        background-color: #0b5ed7;
        border-color: #0a58ca;
    }
    &:active {
        color: #fff;
        background-color: #0a58ca;
        border-color: #0a53be;
    }
}
.btn-success {
    color: #fff;
    background-color: #198754;
    border-color: #198754;
    &:hover {
        color: #fff;
        background-color: #157347;
        border-color: #146c43;
    }
    &:active {
        color: #fff;
        background-color: #146c43;
        border-color: #13653f;
    }
}

.wrapper_submenu {
    position: relative;
    display: inline-block;
    & .subButton {
        position: absolute;
        top:100%;
        left: 0;
        width: 200px;
        padding: 5px;
        border: 1px solid #ccc;
        background: #fff;
        z-index: 999;
        & .label {
            padding: 5px 0;
            font-size: 0.8em;
            text-transform: uppercase;
            font-weight: bold;
            color:#5b5b5b;
        }
    }
}

.textarea {
    max-width: calc(100% - 12px);
    min-width: calc(100% - 12px);
    display: block;
    outline: none;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 2px;
    background: #fff;
    font-size: 1em;
}
.input {
    width: calc(100% - 12px);
    display: block;
    outline: none;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 2px;
    background: #fff;
    font-size: 1em;

    &.disabled {
        background: #ededed;
    }
}
.select {
    width: 100%;
    display: block;
    outline: none;
    border: 1px solid #ccc;
    padding: 5px;
    border-radius: 2px;
    background: #fff;
    font-size: 1em;
}

.container {
    padding: 10px;
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 3px;
    margin: 10px 0;
}

.table-scroll {
    width: 100%;
    background: #fff;
    border-collapse: collapse;
    //border: 1px solid #ccc;

    &__wrapper {
        border: 1px solid #EAEDF3;
        position: relative;
        overflow: hidden;
        overflow-x: auto;
        overflow-y: auto;
        max-height: 70vh;

        scrollbar-width: thin;
        scrollbar-color: #dbe2eb transparent;

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #dbe2eb;
            //border-radius: 5px;
            border: 1px solid transparent;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #bfc2c4;
            cursor: pointer;
        }
    }

    & thead {
        & tr {}
        & th {
            //border: 1px solid #ccc;
            font-weight: bold;
            color:#687D96;
            padding: 5px;
            white-space: nowrap;
            position: sticky;
            top:0;
            cursor: pointer;
            z-index: 1;

            &.wrap {
                white-space: normal;
            }

            &.active {
                color: #333333;
                &:before {
                    background: #e2e2e2;
                }
            }

            &.width_fixed { min-width: 200px; }


            & .table__title {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                position: relative;
                z-index: 3;

                &.text-l {
                    justify-content: flex-start;
                }
                &.text-r {
                    justify-content: flex-end;
                }

                & .label {
                    font-size: 0.9em;
                }

                & .sort {
                    margin-left: 5px;
                    font-weight: normal;
                }
            }

            &:before {
                content: "";
                position: absolute;
                top:0;
                left: 0;
                right: 0;
                bottom: 0;
                background: #f9fafb;
                z-index: 2;
                border-bottom: 1px solid #EAEDF3;
            }

            &:hover:before {
                background: #efefef;
            }
        }
    }
    & tbody {
        & tr {
            border-bottom: 1px solid #EAEDF3;
            &:last-child {
                border-bottom: none;
            }
            &:hover {
                background: #F1F4F8;
            }
        }
        & td {
            white-space: nowrap;
            padding: 5px;
            //border: 1px solid #ccc;
            text-align: center;
            &.wrap {
                white-space: normal;
            }
            &.notBorder {
                padding: 0;
            }
            &.warn { background: #ffed4a; }
            &.danger { background: #ff6363; color: #fff; }
            &.success { background: #2cc185; color: #fff; }
            &.width_max { max-width: 200px!important; }
            &.wrap { white-space: normal!important; }
        }
        & .text-left {
            text-align: left;
        }
        & .label {
            font-size: 0.8em;
            text-transform: uppercase;
            font-weight: bold;
            color:#5b5b5b;
        }
        & .hideBlock {
            max-height: 150px;
            overflow: auto;
        }
    }
    &.hover {
        & tbody tr:hover {
            background: #f3f3f3;
        }
    }
}

.table {
    width: 100%;
    background: #fff;
    border-collapse: collapse;
    border: 1px solid #ccc;

    & thead {
        background: #f9fafb;

        & th {
            border: 1px solid #ccc;
            font-weight: bold;
            color:#687D96;
            padding: 5px;
            font-size: 0.9em;
        }
    }
    & tbody {
        & td {
            white-space: nowrap;
            padding: 5px;
            border: 1px solid #ccc;
            text-align: center;
            &.notBorder {
                padding: 0;
            }
            &.warn {
                background: #ffed4a;
            }
            &.wrap {
                white-space: normal;
            }
        }
        & .text-left {
            text-align: left;
        }
        & .label {
            font-size: 0.8em;
            text-transform: uppercase;
            font-weight: bold;
            color:#5b5b5b;
        }
        & .hideBlock {
            max-height: 150px;
            overflow: auto;
        }
    }
    &.hover {
        & tbody tr:hover {
            background: #f3f3f3;
        }
    }
}

.authPage {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & .title {
        text-transform: uppercase;
        font-weight: 600;
        font-size: 1em;
        padding: 20px 0;
    }

    & .errorNotify {
        padding: 5px;
        background: #e3342f;
        color: #fff;
        text-align: center;
        border-radius: 3px;
    }
    & .successNotify {
        padding: 5px;
        background: #2cc185;
        color: #fff;
        text-align: center;
        border-radius: 3px;
    }

    & .loginForm {
        min-width: 280px;
        max-width: 400px;
        width: 100%;
        padding: 20px;
        background: #fff;
        border-radius: 4px;
        border: 1px solid #eee;
    }
    & .links {
        padding: 10px 0;
        display: flex;
        flex-direction: row;
        & a {
            display: block;
            font-size: 0.8em;
            color: #9561e2;
            text-decoration: underline;
            padding: 5px 10px;
            &:hover {
                text-decoration: none;
            }
        }
    }
}

.block {
    background: #fff;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin: 10px;

    & .toolbar {
        margin: 10px 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-content: center;
        & .paginate {
            margin: 0;
        }
    }

    &.notBorder {
        margin: 0;
        & > .title {
            padding: 5px;
        }
        & > .body {
            padding: 0px;
            & .formBlock {
                padding: 0px;
            }
        }
    }

    &.smallBlock {
        & > .title {
            padding: 5px;
        }
        & > .body {
            padding: 5px;
            & .formBlock {
                padding: 5px;
            }
        }
    }

    & .title {
        padding: 10px;
        border-bottom: 1px solid #ccc;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 0.8em;
        border-radius: 5px 5px 0 0;
        color:#333;
        background:#cbd5e1;

        & a {
            font-size: 1em;
            color:#fff;
            text-decoration: none;
            &:hover {
                text-decoration: underline;
            }
        }
    }

    & .footer {
        background: #f4f4f4;
        padding: 10px;
        border-top: 1px solid #ccc;
        border-radius: 0 0 5px 5px;
    }

    & .body {
        padding: 10px;

        &.overflow {
            overflow: hidden;
            overflow-x: auto;
            & td {
                width: 200px;
            }
        }

        & .documents {
            padding: 10px;
        }
        & .images {
            padding: 5px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            & .image {
                margin: 5px;
                width: 200px;
                height: 200px;
                background-position: center center;
                background-size: cover;
                position: relative;
                cursor: pointer;
                background-color: #f1f1f1;

                & .icon-preview {
                    width: 200px;
                    height: 50px;
                    position: absolute;
                    top: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    transform: translateY(-50%);
                }

                &:hover & .delete {
                    display: flex;
                }
                & .main {
                    left: 0;
                }
                & .selected {
                    background: rgba(13, 110, 253, 0.6);
                    left: 0;
                }
                & .delete {
                    background: rgba(253, 13, 13, 0.6);
                    display: none;
                    right: 0;
                }
                & .main, & .selected, & .delete {
                    width: 50%;
                    height: 50px;
                    position: absolute;
                    bottom: 0;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    color: #fff;
                }
            }
        }
    }

}


.table-filter {
    margin: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;

    .formBlock {
        padding: 5px;
        display: flex;
        flex-direction: column;

        .label {
            padding: 0;
            margin-right: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }
}

.drag-in-drop {
    margin: 10px;
    padding: 10px;
    border: 2px dashed #4dc0b5;
    cursor: pointer;

    & input[type="file"] {
        display: none;
    }

    &:hover {
        background: #eaeaea;
    }
    &.over {
        background: #eaeaea;
    }

    & .label {
        text-align: center;
        padding: 50px;
        color: #4dc0b5;
        font-size: 0.8em;
    }
}

.default-page {
    position: relative;
    padding-bottom: 60px;
    & .buttons {
        padding: 10px;

        &__fixed {
            position: fixed;
            bottom: 0;
            left: 90px;
            right: 0;
            background: #fff;
            padding: 10px;
            border-top: 1px solid #ccc;
            z-index: 10;
        }
    }
}

.mx-datepicker-popup {
    z-index: 99999!important;
}
.mx-datepicker {
    width: 100%!important;
    & .mx-input {
        outline: none;
        border: 1px solid #ccc;
        padding: 5px;
        border-radius: 2px;
        background: #fff;
        font-size: 1em;
        box-shadow:none;
        -moz-box-shadow: none;
        -webkit-box-shadow: none;
        &:hover {
            border: 1px solid #ccc;
        }
    }
}

.formBlock {
    .mx-datepicker {
        width: calc(100% - 12px) !important;
    }
}

.defaultMultiselect {
    &.inline {
        display: inline-block;
    }
    & .multiselect {
        cursor: pointer;
        position: relative;

        & .multiselect__placeholder {
            color: #ccc;
        }

        & .multiselect__tags {
            width: calc(100% - 12px);
            display: block;
            outline: none;
            border: 1px solid #ccc;
            padding: 5px;
            border-radius: 2px;
            background: #fff;
            font-size: 1em;
            & .multiselect__tag {
                overflow: hidden;
                font-weight: bold;
                border-radius: 2px;
                background: #2cc185;
                color: #fff;
                margin: 3px;
                display: inline-block;
                & span {
                    font-size: 0.8em;
                    padding: 3px 3px;
                    display: inline-block;
                }
                & .multiselect__tag-icon {
                    padding: 3px;
                    font-size: 0.8em;
                    border-left: 1px solid #fff;
                    &:hover {
                        background: #30db96;
                    }
                    &:before {
                        content: 'x';
                        font-style: normal;
                    }
                }
            }
        }
        & .multiselect__content-wrapper {
            position: absolute;
            border: 1px solid #ccc;
            border-radius: 0 0 2px 2px;
            top: 100%;
            left: 0;
            width: calc(100% - 12px);
            background: #fff;
            overflow: hidden;
            overflow-y: auto;
            z-index: 999;


            & ul {
                width: 100%;
            }
            & li {
                list-style: none;
                border-bottom: 1px solid #ccc;
                cursor: pointer;
                &:hover {
                    background: #f3f3f3;
                }
                &:last-child {
                    border-bottom: none;
                }
                & > span {
                    padding: 10px;
                    display: block;
                }
            }
            & .multiselect__option--selected {
                font-weight: bold;
                background: #f3f3f3;
            }
        }

        & .multiselect__input {
            border: none;
            outline: none;
        }
    }
}


.flex-row {
    display: flex;
    flex-direction: row;
}
.flex-row-wrap {
    display: flex;
    flex-flow: row wrap;
}
.flex-space-between {
    justify-content: space-between;
}
.align-center {
    align-items: center;
}

.grid { display: grid; }
.grid-1-1 { grid-template-columns: 1fr 1fr; }

.modalWindow {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,.5);
    overflow-y: auto;
    display: none;
    z-index: 999;

    &.active {
        display: block;
    }


    & .modalClose {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 99;
    }

    & .modalContainer {
        background: #fff;
        border: 1px solid #ccc;
        border-radius: 3px;
        z-index: 9999;
        max-width: 500px;
        min-width: 320px;
        margin: 0 auto;
        margin-top: 50px;
        position: absolute;
        top:10%;
        transform: translateX(-50%);
        left:50%;
        //margin-left: -160px;

        &.large {
            max-width: 500px;
            width: 100%;
        }
        &.full-window {
            top:20px;
            left:20px;
            right:20px;
            margin-bottom: 20px;
            max-width: none;
            width: auto;
            transform:none;


            @include mobile {
                position: fixed;
                top:0;
                left: 0;
                bottom: 0;
                right: 0;
                margin-bottom: 0;
                margin-top: 0;
                overflow-y: auto;

                & .header {
                    position: sticky;
                    top: 0;
                }
            }
        }

        & .body {
            padding: 10px;
        }
        & .footer {
            background: #fff;
            padding: 10px;
            border-top: 1px solid #ccc;
        }
        & .header {
            padding: 10px;
            border-bottom: 1px solid #ccc;
            text-transform: uppercase;
            font-weight: bold;
            font-size: 0.8em;
            color:#5b5b5b;
            position: relative;
            & .close {
                position: absolute;
                top: 0;
                right: 0;
                padding: 10px;
                cursor: pointer;
                &:before {
                    content: 'x';
                }
            }
        }
    }
    &.errorModal {
        & .modalContainer {
            border-color: #e3342f;
        }
        & .header {
            background: #e3342f;
            color: #fff;
        }
    }
    &.successModal {
        & .modalContainer {
            border-color: #485779;
        }
        & .header {
            background: #485779;
            color: #fff;
        }
    }
}

.paginate {
    background: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 3px;
    margin: 10px 0;
    overflow: hidden;
    & li {
        list-style: none;
        &.active {
            & a {
                background: #4273FA;
                border: 1px solid #4273FA;
                color: #fff;
            }
        }
        & a {
            padding: 7px 10px;
            border: 1px solid #ccc;
            display: block;
        }
    }
}

.notifications {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999;

    .notify {
        margin: 5px;
        background: #fff;
        min-width: 300px;
        border-radius: 3px;
        overflow: hidden;

        & .title {
            padding: 7px;
            color: #fff;
            font-weight: bold;
            position: relative;
            & .close {
                color: #fff;
                position: absolute;
                top:0;
                right:0;
                padding: 5px 10px;
                cursor: pointer;
            }
        }
        & .body {
            padding: 7px;
        }

        &__error {
            & .title { background: #e90000; }
            & .body { background: #e90000; color: #fff; }
        }
        &__success {
            & .title { background: #2dd4bf; }
            & .body { background: #2dd4bf; color: #fff; }
        }
    }
}
.loadingModal {
    position: fixed;
    background: rgba(255,255,255,0.75);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: none;

    &.active {
        display: block;
    }

    & div {
        display: flex;
        flex-direction: column;
        text-align: center;
        width: 200px;
        height: 200px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -100px;
        margin-left: -100px;
    }
}


.dropdown-menu {
    display: inline-block;

    position: relative;

    & .menu-title {
        padding: 5px 10px;
    }
    & .menu-button {
        padding: 5px 10px;
        border-left: 1px solid #e0e0e0;
    }

    &__container {
        display: flex;
        flex-direction: row;
        padding: 0;
    }
    &__dropdown {
        display: none;
        top: 100%;
        //margin-top: 5px;
        position: absolute;
        left: 0;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 3px;
        overflow: hidden;
        flex-direction: column;

        .dropdown-menu:hover & {
            display: flex;
        }

        & .button {
            margin: 0;
            text-align: left;
            border-radius: 0;
            white-space: nowrap;
            border-bottom: 1px solid #e0e0e0;
            &:last-child {
                border-bottom: none;
            }
        }
    }
}

@media screen and (max-width: 670px) {
    .w10, .w20, .w25, .w30, .w33, .w40 {
        width: 100%;
    }


    .left {
        z-index: 20;
        display: none;
        &.open {
            display: block;
        }
        & .menu {
            background: #485779;
            position: relative;

            & li {
                &.mobile-button {
                    display: block;
                }
            }
        }
        & .wrapper-mobile {
            position: fixed;
            top:0;
            left: 0;
            right: 0;
            bottom: 0;
            background: rgba(0,0,0,.6);
        }
    }
    .right {
        z-index: 10;
        margin-left: 0;
        & .content {
            margin-top: 60px;
            padding: 0;
        }
        & .top {
            padding: 0;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            overflow-x: auto;
            & .user {
                display: none;
            }
            & .title {
                padding: 15px;
            }
            & .mobile-menu {
                display: block;
                & .icon {
                    font-size: 2em;
                    padding: 15px;
                    cursor: pointer;
                }
            }
        }
    }

    .block {
        & .toolbar {
            flex-direction: column;
        }
        & .body {
            padding: 5px;
        }
    }
}
@media screen and (max-width: 450px) {
    .w50, .w60, .w75, .w80, .w90 {
        width: 100%;
    }
}

.image-cover-top{
    width: 400px;
    height: 133px;
}
.image-cover-bottom{
    width: 200px;
    height: 133px;
    margin-right: 200px;
}
@media screen and (max-width: 760px) {
    .image-cover-top{
        margin:0 auto;
        margin-bottom: 20px;
        max-width: 100%;
    }
    .image-cover-bottom{
        margin:0 auto;
        margin-bottom: 20px;
    }
    .flex-row-image{
        flex-direction: column;
    }
}


.order-page {
    .label {
        font-size: 12px;
        font-weight: bold;
        white-space: nowrap;
        //text-decoration: underline;
        //text-transform: uppercase;
    }
    .select-label {
        font-size: 12px;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
    }

    .select {
        width: 100%;
        display: block;
        outline: none;
        border: none;
        border-bottom: 1px solid #ccc;
        padding: 2px 3px;
        background: #fff;
        font-size: 12px;
    }

    .send-message {
        display: flex;
        textarea {
            min-height: 50px;
            height: 50px;
            flex: 1;
            min-width: auto;
        }
        button {
            border: none;
            background: #0b5ed7;
            color: #ffffff;
            font-size: 12px;
            font-weight: 600;
            padding: 10px;
            cursor: pointer;
            &:hover {
                background: #1e72ee;
            }
        }
    }

    .messages {
        overflow-y: auto;
        max-height: 300px;

        scrollbar-width: thin;
        scrollbar-color: #dbe2eb transparent;

        &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
            cursor: pointer;
        }
        &::-webkit-scrollbar-track {
            background: transparent;
        }
        &::-webkit-scrollbar-thumb {
            background: #dbe2eb;
            //border-radius: 5px;
            border: 1px solid transparent;
            -webkit-transition: all .3s ease;
            transition: all .3s ease;
        }

        &::-webkit-scrollbar-thumb:hover {
            background-color: #bfc2c4;
            cursor: pointer;
        }

        .message {
            background: #f4f4ff;
            max-width: 70%;
            width: 100%;
            border-radius: 0 7px 7px 7px;
            padding: 5px;

            &__wrapper {
                display: flex;
                justify-content: left;
                margin: 5px;
                &.my__wrapper {
                    justify-content: right;
                }

                .read {
                    height: 5px;
                    width: 5px;
                    background: #1e72ee;
                    border-radius: 10px;
                }
            }

            .info {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;


                .date {
                    font-size: 0.8em;
                    font-weight: 600;
                    color: #9c9c9c;
                }
            }

            &.my {
                background: #d9fdd3;
                border-radius: 7px 0 7px 7px;
            }



        }
    }
}


.widget {
    margin: 10px;
    border-radius: 10px;
    background: #ffffff;
    -webkit-box-shadow: 2px 2px 5px rgb(0 0 0 / 8%);
    box-shadow: 2px 2px 5px rgb(0 0 0 / 8%);

    .title {
        padding: 15px 20px;
        border-bottom: 1px solid #cccccc;
        font-size: 20px;
        font-weight: 300;
        color: #73818f;
        //border-radius: 10px;
        //overflow: hidden;
    }

    .body {
        padding: 10px 20px;
    }

    .metrics {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }
    .metric {
        padding: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .value {
            font-size: 30px;
            font-weight: 500;
            color: #73818f;
        }

        .label {
            margin-top: 5px;
            font-weight: 600;
            font-size: 12px;
            color: #73818f;
        }
    }
}



.slideModal {
    display: none;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 100%;
    opacity: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 99999;
    transition: 0.145s all ease;

    .slideModalClose {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 300%;
    }

    &.open {
        display: block;
        left: 0;
        opacity: 1;
        .modalContainer {
            left: 40%;
        }
        .slideModalClose {
            left: 0;
        }
    }

    > .modalContainer {
        position: fixed;
        overflow-y: auto;
        top: 0;
        bottom: 0;
        right: 0;
        left: 300%;
        background: #F5F8FF;
        transition: 0.145s all ease;

        & > .head {
            background: #fff;
            border-bottom: 1px solid #D6DEE3;
            color: #73818f;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 15px;
            z-index: 999;
            white-space: nowrap;
            position: fixed;
            width: calc(60% - 15px);
            top: 0;
            overflow: hidden;

            .title {
                font-weight: bold;
                text-align: center;
            }
        }

        & > .bottom {
            background: #fff;
            border-top: 1px solid #D6DEE3;
            padding: 15px;
            position: fixed;
            width: calc(60% - 15px);
            bottom: 0;
        }

        & > .body {
            padding: 60px 10px 80px;

            .back {
                margin-left: 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                flex-wrap: nowrap;

                .icon {
                    width: 40px;
                    height: 40px;
                    background: #fff;
                    border: 1px solid #D6DEE3;
                    border-radius: 50px;
                    position: relative;

                    &:hover {
                        background: #f3f3f3;
                    }

                    &:before {
                        font-size: 22px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translateX(-50%) translateY(-50%);
                        font-family: "Material Icons";
                        content: "\E314";
                    }
                }

                .text {
                    color: #73818f;
                    margin-left: 10px;
                    display: flex;
                    align-items: center;

                    &:hover {

                    }

                    &:after {
                        font-family: "Material Icons";
                        display: inline-block;
                        content: "\E315";
                        padding-left: 10px;
                        text-decoration: none;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }

            }
        }
    }

    @media screen and (max-width: 1000px) {
        &.open {
            display: block;
            left: 0;
            opacity: 1;
            .modalContainer {
                left: 0;
            }
            .slideModalClose {
                left: 0;
            }
        }

        .modalContainer {
            & > .head {
                width: calc(100% - 15px);
            }
            & > .body {}
            & > .bottom {
                width: calc(100% - 15px);
            }
        }

    }
}


.fileManager {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .file {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 5px;
        padding: 5px;
        border-radius: 3px;
        cursor: pointer;
        width: 90px;
        height: 95px;
        .icon {
            font-size: 40px;
        }
        .text {
            font-size: 12px;
            text-align: center;
            margin-top: 3px;

            max-height: 40px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
        }
        &:hover {
            background: rgba(13, 110, 253, 0.25);
        }
    }

    &.tableView {
        flex-direction: column;

        .headerInfo {
            display: grid;
            grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;
            //grid-column-gap: 10px;

            .info {
                font-size: 10px;
                text-transform: uppercase;
                font-weight: 600;
                text-align: center;
                cursor: pointer;
                padding: 5px 0;

                display: flex;
                gap: 7px;
                justify-content: center;
                align-items: center;

                &:hover {
                    background: #eeeeee;
                }

                .icon {
                    font-size: 10px;
                    font-weight: normal;
                }
            }
        }

        .file {
            display: grid;
            grid-template-columns: 3fr 1fr 1fr 1fr 1fr 1fr;

            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: auto;
            height: auto;
            cursor: default;
            margin: 0 5px;
            padding: 2px 5px;

            .info {
                flex: 1;
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
                .icon {
                    font-size: 20px;
                }
                .text {
                    margin-left: 10px;
                    margin-top: 0;
                    text-align: left;
                }
            }

            .actions {
                display: flex;
                justify-content: flex-end;
                .icon {
                    border-radius: 3px;
                    cursor: pointer;
                    display: block;
                    font-size: 18px;
                    color: rgb(13, 110, 253);
                    padding: 5px;

                    &:hover {
                        background: rgb(13, 110, 253);
                        color: #fff;
                    }

                    &.icon-delete {
                        color: #dc3545;

                        &:hover {
                            background: #dc3545;
                            color: #fff;
                        }
                    }

                }
            }

        }
    }

    .back {
        margin-left: 5px;
        margin-top: 10px;
        margin-bottom: 10px;
        cursor: pointer;
        display: flex;
        align-items: center;
        flex-wrap: nowrap;

        .text {
            color: #73818f;
            margin-right: 10px;
            display: flex;
            align-items: center;
            font-size: 12px;

            &:hover {

            }

            &:after {
                font-family: "Material Icons";
                display: inline-block;
                content: "\E315";
                padding-left: 10px;
                text-decoration: none;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }

    }
}

@mixin maxLines($lines, $lineHeight) {
    max-height: #{$lines * $lineHeight}px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
}

.hidden-before-3-lines {
    @include maxLines(3, 22)
}
